import AxiosConfig from '@/api/config'

const baseURL = '/client-signature'

const uploadSignature = async (payload) => {
    const url = `${baseURL}`

    const { data } = await AxiosConfig.post(url, payload)
    return data.data ? data.data : data
}

const updateSignature = async (id, payload) => {
    const url = `${baseURL}/${id}`

    const { data } = await AxiosConfig.put(url, payload)
    return data.data ? data.data : data
}

const deleteSignature = async (id) => {
    const url = `${baseURL}/${id}`

    const { data } = await AxiosConfig.delete(url)
    return data.data ? data.data : data
}

export default {
    uploadSignature,
    updateSignature,
    deleteSignature
}

// Inventory Routes
export default [
    {
        path: 'inventory',
        name: 'InventoryView',
        component: () => import('@/views/admin/inventory/InventoryView'),
        meta: {
            title: 'My Abseil - Inventory',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'inventory/edit/:id',
        name: 'InventoryEditView',
        component: () => import('@/views/shared/InventoryEditView'),
        meta: {
            title: 'My Abseil - Inventory',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'inventory/details/:id',
        name: 'InventoryDetailsView',
        component: () => import('@/views/admin/inventory/InventoryDetailsView'),
        meta: {
            title: 'My Abseil - Inventory',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'inventory/multiple',
        name: 'MultipleInventoryView',
        component: () => import('@/views/shared/MultipleInventoryView'),
        meta: {
            title: 'My Abseil - Multiple Inventory',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

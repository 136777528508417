export default [
    {
        path: 'forms',
        name: 'TeamLeadForms',
        component: () => import('@/views/team-lead/FormsView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'notes',
        name: 'TeamLeadNotesView',
        component: () => import('@/views/team-lead/NotesView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'attachments',
        name: 'TeamLeadAttachmentsView',
        component: () => import('@/views/team-lead/AttachmentsView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'free-forms/:tab?',
        name: 'TeamLeadFreeFormsView',
        component: () => import('@/views/team-lead/TeamLeadFreeFormsView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id/details',
        name: 'TeamLeadFreeFormsDetailsView',
        component: () => import('@/views/team-lead/TeamLeadFreeFormsDetailsView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id?/edit',
        name: 'TeamLeadFreeFormsFill',
        component: () => import('@/views/team-lead/TeamLeadFreeFormsFill'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'operatives/details/:id',
        name: 'TeamLeadOperativesDetailsView',
        component: () =>
            import('@/views/team-lead/TeamLeadOperativeView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'settings',
        name: 'TeamLeadSettings',
        component: () => import('@/views/team-lead/TeamLeadSettingsView'),
        meta: { requiresAuth: true, requiresTeamLead: true }
    },
    {
        path: 'settings/edit/',
        name: 'EditUserView',
        component: () => import('@/views/team-lead/EditUserView'),
        meta: {
            requiresAuth: true,
            requiresTeamLead: true
        }
    },
    {
        path: 'resources',
        name: 'TeamLeadResourcesView',
        component: () => import('@/views/team-lead/TeamLeadResourcesView'),
        meta: {
            requiresAuth: true,
            requiresTeamLead: true
        }
    },
    {
        path: 'notifications',
        name: 'TeamLeadNotificationsView',
        component: () => import('@/views/shared/NotificationsView'),
        meta: {
            requiresAuth: true,
            requiresTeamLead: true
        }
    }
]

import AxiosConfig from '@/api/config'
import { fileNameParser } from '@/utils/Utilities'

const getAll = async (modelName, params) => {
    const url = '/' + modelName

    const { data } = await AxiosConfig.get(url, { params })

    if (!data) return []

    return data
}

const getModelByID = async (modelName, modelID) => {
    const url = `/${modelName}/${modelID}`

    const { data } = await AxiosConfig.get(url)

    if (!data) return {}

    return data.data
}

const createModel = async (modelName, modelPayload) => {
    const url = `/${modelName}`

    const { data } = await AxiosConfig.post(url, modelPayload) || {}

    if (!data) return {}

    return data.data
}

const updateModel = async (modelName, modelID, modelPayload) => {
    const url = `/${modelName}/${modelID}`

    const { data } = await AxiosConfig.patch(url, modelPayload) || {}

    if (!data) return {}

    return data.data
}

const deleteModel = async (modelName, modelID, endpoint = '') => {
    const url = `/${modelName}/${modelID}/${endpoint}`

    const { data } = await AxiosConfig.delete(url) || {}

    if (!data) return {}

    return data
}

const restoreModel = async (modelName, modelID) => {
    const url = `/${modelName}/${modelID}/restore`

    const { data } = await AxiosConfig.post(url) || {}

    if (!data) return {}

    return data
}

const getModelAttachment = async (params) => {
    const url = '/download-file'
    const filename = params.original_filename || fileNameParser(params.filename, params.type)
    const requiredParams = { filename: params.filename, type: params.type }

    const { data } = await AxiosConfig.get(url, {
        params: requiredParams,
        responseType: 'blob'
    })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', filename)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

const previewModelAttachment = async (params) => {
    const url = '/download-file'

    const { data } = await AxiosConfig.get(url, {
        params,
        responseType: 'blob'
    })

    const objectUrl = URL.createObjectURL(data)

    return objectUrl
}

const deleteFileAttachment = async (id) => {
    const url = `file-attachment/${id}`

    const { data } = await AxiosConfig.delete(url)

    return data.data ? data.data : data
}

const deleteFileResource = async (id) => {
    const url = `documents/${id}`

    const { data } = await AxiosConfig.delete(url)

    return data.data ? data.data : data
}

const exportToPDF = async (modelName, params = {}, method = 'get', filename = '', endpoint = 'generate_pdf') => {
    const url = `${modelName}/${endpoint}`

    const lowercaseMethod = method.toLowerCase()

    const blobHeader = {
        responseType: 'blob'
    }

    const { data } = await AxiosConfig[lowercaseMethod](url, lowercaseMethod === 'get' ? { params, ...blobHeader } : { ...params }, blobHeader)

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `${filename}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

const deleteSignature = async (modelName, modelID) => {
    const url = `/${modelName}/${modelID}`

    const { data } = await AxiosConfig.delete(url)

    return data.data ? data.data : data
}

const deleteMedia = async (modelName, questionId, mediaId) => {
    const url = `/${modelName}/${questionId}/delete_media/${mediaId}`

    const { data } = await AxiosConfig.post(url)

    return data.data ? data.data : data
}

export default {
    createModel,
    deleteModel,
    getAll,
    getModelByID,
    updateModel,
    getModelAttachment,
    previewModelAttachment,
    deleteFileAttachment,
    exportToPDF,
    deleteFileResource,
    deleteSignature,
    deleteMedia,
    restoreModel
}

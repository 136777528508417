import _ from 'lodash'
import API from '@/api'
import moment from 'moment-timezone'

export default {
    namespaced: true,
    state: {
        workSchedule: {},
        workScheduleStatuses: []
    },
    getters: {
        getWorkSchedule: (state) => state.workSchedule,
        getWorkScheduleStatuses: (state) => state.workScheduleStatuses
    },
    mutations: {
        SET_WORK_SCHEDULE (state, workSchedule) {
            state.workSchedule = workSchedule
        },
        UPDATE_TEAM_SIGNATURE_PROPERTY (
            state,
            { operativeId, property, value }
        ) {
            const obj = { ...state.workSchedule }

            const index = obj.rope_hour.findIndex(
                (operator) => operator.operative_id === operativeId
            )

            obj.rope_hour[index][property] = value
            state.workSchedule = { ...state.workSchedule, ...obj }
        },
        DELETE_TEAM_MEMBER (state, id) {
            const team = state.workSchedule.team.filter(
                (o) => o.id !== id
            )
            state.workSchedule.team = team
        },
        SET_WORK_SCHEDULE_STATUSES (state, workScheduleStatuses) {
            state.workScheduleStatuses = workScheduleStatuses
        }
    },
    actions: {
        async setWorkSchedule ({ commit, rootGetters, getters }) {
            const currentDate = moment
                .tz(rootGetters.getGlobalDate, rootGetters['AuthModule/getTimezone'] ?? 'UTC')
                .format(
                    'YYYY-MM-DD'
                )
            const workSchedule = await API.Models.getAll('workschedules', {
                date: currentDate,
                lead: rootGetters['AuthModule/getUser'].operative_id
            })
            commit('SET_WORK_SCHEDULE', workSchedule.data[0] || {})
        },
        async setWorkScheduleStatuses ({ commit, state }) {
            if (!_.isEmpty(state.workScheduleStatuses)) return
            const workScheduleStatuses = await API.WorkSchedules.getStatuses()
            commit('SET_WORK_SCHEDULE_STATUSES', workScheduleStatuses)
        }
    }
}

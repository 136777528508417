import AxiosConfig from '@/api/config'

const baseURL = '/users'

const getTimeZones = async () => {
    const url = `${baseURL}/available-timezones`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const onTrial = async () => {
    const url = `${baseURL}/on-trial`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

export default {
    getTimeZones,
    onTrial
}

export default [
    {
        path: 'users',
        name: 'UsersView',
        component: () => import('@/views/admin/users/UsersView'),
        meta: {
            title: 'My Abseil - Users',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'users/details/:id',
        name: 'UsersDetailsView',
        component: () => import('@/views/admin/users/UsersDetailsView'),
        meta: {
            title: 'My Abseil - Users',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'users/edit/:id',
        name: 'UsersEditView',
        component: () => import('@/views/admin/users/UsersEditView'),
        meta: {
            title: 'My Abseil - Users',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

// Auth Routes
export default [
    {
        name: 'LoginView',
        path: '/login',
        component: () => import('@/views/auth/LoginView'),
        meta: { title: 'My Abseil - Sign In' }
    },
    {
        name: 'ForgotPasswordView',
        path: '/forgot-password',
        component: () => import('@/views/auth/ForgotPasswordView'),
        meta: { title: 'My Abseil - Forgot Password' }
    },
    {
        name: 'ChangePasswordView',
        path: '/change-password',
        component: () => import('@/views/auth/ChangePasswordView'),
        meta: { title: 'My Abseil - Change Password' }
    }
]

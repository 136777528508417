import Axios from 'axios'
import router from '@/router'
import store from '@/store'

const AxiosConfig = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL
})

const addToken = (config) => {
    const token = store.getters['AuthModule/getAuthToken']
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
}

let numOfRequests = 0

AxiosConfig.interceptors.request.use(
    (config) => {
        addToken(config)
        numOfRequests++
        if (store.state.disableLoader) {
            return config
        }
        store.commit('SET_LOADER_TO_STATE', true)
        return config
    },
    (error) => {
        store.commit('SET_LOADER_TO_STATE', false)
        numOfRequests--
        return Promise.reject(error)
    }
)

AxiosConfig.interceptors.response.use(
    (response) => {
        numOfRequests--
        if (numOfRequests === 0) {
            store.commit('SET_LOADER_TO_STATE', false)
        }
        return response
    },
    (error) => {
        store.commit('SET_LOADER_TO_STATE', false)
        numOfRequests--
        if (numOfRequests === 0) {
            store.commit('SET_LOADER_TO_STATE', false)
        }
        if (
            error.response.status === 401 &&
            error?.response?.data?.error?.message === 'Unauthenticated.'
        ) {
            store.dispatch('AuthModule/logoutUser')
            return
        }

        if (
            error.response.status === 403
        ) {
            store.dispatch('BaseAlertModule/setAlert', {
                message: router.app.$i18n.t('global.unauthorized'),
                type: 'error'
            })
            throw new Error(JSON.stringify({ message: router.app.$i18n.t('global.unauthorized'), status: false }))
        }

        if (error?.response?.data?.error) {
            throw new Error(
                error?.response?.data?.error.message ||
                    error.response.data.error ||
                    error.response.data
            )
        } else if (error?.response?.data?.errors) {
            const errors = {}
            const errorsList = error?.response?.data?.errors

            for (const key in errorsList) {
                if (Object.prototype.hasOwnProperty.call(errorsList, key)) {
                    errors[key] = errorsList[key][0]
                }
            }

            throw new Error(JSON.stringify(errors))
        } else if (error) {
            throw new Error(error.response.data.message)
        } else {
            throw new Error('Network Error')
        }
    }
)

export default AxiosConfig

import AxiosConfig from '@/api/config'

const getAllCategories = async (model) => {
    const url = `${model}/categories`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const getCategory = async (model, id) => {
    const url = `${model}/categories/${id}`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const createCategory = async (model, payload) => {
    const url = `${model}/categories/`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const deleteCategory = async (model, id) => {
    const url = `${model}/categories/${id}`

    const { data } = await AxiosConfig.delete(url)

    if (!data) return []

    return data
}

export default {
    getAllCategories,
    deleteCategory,
    getCategory,
    createCategory
}

import AxiosConfig from '@/api/config'

const baseURL = '/ropehours'

const getAllRopeHours = async (workerID) => {
    const { data } = await AxiosConfig.get('/ropehours/1')
    return data
}

const getRopeHoursByWorkSchedule = async (workScheduleID) => {
    const url = `/workschedule?work_schedule_id=${workScheduleID}`

    const { data } = await AxiosConfig.get(url)
    return data
}

const updateRopeHours = async (ropeHourID, payload) => {
    const url = `${baseURL}/${ropeHourID}`

    const { data } = await AxiosConfig.patch(url, payload)
    return data
}

const uploadSignature = async (ropeHourID, image) => {
    const url = `${baseURL}/${ropeHourID}/signature`

    const { data } = await AxiosConfig.post(url, { image })
    return data.data ? data.data : data
}

const getRopeHoursSignature = async (ropeHourID) => {
    const url = `${baseURL}/${ropeHourID}/download-signature`

    const { data } = await AxiosConfig.get(url, {
        responseType: 'arraybuffer'
    })
    return data
}

export default {
    getAllRopeHours,
    getRopeHoursByWorkSchedule,
    updateRopeHours,
    uploadSignature,
    getRopeHoursSignature
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'

Vue.use(VueI18n)

// eslint-disable-next-line
const i18n = new VueI18n({
    locale: store.getters['AuthModule/getUserLanguage'],
    silentFallbackWarn: true,
    messages: {
        en: require('./en.json'),
        fr: require('./fr.json')
    }
})

export default i18n

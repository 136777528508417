import db from '@/offline/indexedDBHelper'

export default {
    data () {
        return {
        // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false
        }
    },

    created () {
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            console.log('Controller loaded')
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload(true)
        })
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable () {
            db.delete().then(() => {
                console.log('Database successfully deleted')
            }).catch(() => {
                console.error('Could not delete database')
            }).finally(() => {
                // Do what should be done next...
            })
        }
    }
}

import AxiosConfig from '@/api/config'

const baseURL = '/tenants'

const getTenant = async (id) => {
    const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

    if (!data) return []

    return data.data
}

const updateTenant = async (id, payload) => {
    const { data } = await AxiosConfig.put(`${baseURL}/${id}`, payload)

    if (!data) return []

    return data
}

const downloadLogo = async (id) => {
    const url = `${baseURL}/${id}/download-logo`

    const { data } = await AxiosConfig.get(url)

    return data
}

const attachLogo = async (id, payload) => {
    const url = `${baseURL}/${id}/attach-logo`

    const { data } = await AxiosConfig.post(url, payload)

    return data
}

const getTenantBilling = async (id, params) => {
    const url = `${baseURL}/${id}/invoices`

    const { data } = await AxiosConfig.get(url, { params })

    if (!data) return []

    return data.data
}

const downloadInvoice = async (id, invoiceId) => {
    const url = `${baseURL}/${id}/invoices/${invoiceId}/download`

    const { data } = await AxiosConfig.get(url, {
        responseType: 'blob'
    })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `${invoiceId}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

export default {
    getTenant,
    downloadLogo,
    updateTenant,
    attachLogo,
    getTenantBilling,
    downloadInvoice
}

import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/tags'

const getTags = async (params) => {
    const url = `${baseURL}/`

    const offlineData = await db.tags.where('type').equals(params.type).toArray()

    if (offlineData.length > 0) {
        return { data: offlineData }
    }

    const { data } = await AxiosConfig.get(url, { params: { ...params, dropdown: 1, sort: 'name', direction: 'asc' } })

    if (!data) return []

    // Save to IndexedDB
    const offlineDataToSave = data?.data?.map(item => ({
        id: item.id,
        type: params.type,
        ...item
    }))

    await db.tags.bulkPut(offlineDataToSave)

    return data
}

const getTagTypes = async () => {
    const url = `${baseURL}/types`
    const data = await fetchAndStoreData(url, db.tags_types)

    const hasItemWithValueProperty = data.some(item => item.value)

    if (hasItemWithValueProperty) return data.map(item => item.value)
    return data
}

const addTag = async (payload) => {
    const url = `${baseURL}/`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const editTag = async (id, payload) => {
    const url = `${baseURL}/${id}`

    const { data } = await AxiosConfig.put(url, payload)

    if (!data) return []

    return data
}

const deleteTag = async (id) => {
    const url = `${baseURL}/${id}`

    const { data } = await AxiosConfig.delete(url)

    if (!data) return []

    return data
}

export default {
    getTags,
    getTagTypes,
    addTag,
    editTag,
    deleteTag
}

import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        inspectionsStatusTypes: []
    },
    getters: {
        getInspectionsStatuses: (state) => state.inspectionsStatusTypes
    },
    mutations: {
        SET_INSPECTIONS_STATUSES (state, statuses) {
            state.inspectionsStatusTypes = statuses
        }
    },
    actions: {
        async setInspectionsStatuses ({ commit, state }) {
            if (!_.isEmpty(state.inspectionsStatusTypes)) return
            const statuses = await API.Inspections.getResultTypes()
            commit('SET_INSPECTIONS_STATUSES', statuses)
        }
    }
}

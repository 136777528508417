<template>
    <MainLayout
        class="bg-white"
        padding="10px"
    >
        <div v-if="!loading">
            <div class="flex items-center justify-between flex-wrap">
                <div />

                <div class="flex xl:contents">
                    <div class="heading-1 text-grey-800 flex">
                        <p class="my-auto mr-3">
                            {{ getDate | DateFilter }}
                        </p>
                    </div>

                    <div class="flex justify-end w-1/4">
                        <div class="mx-3 my-auto job-allocation-view--filter">
                            <BaseDatePicker
                                v-model="getDate"
                                date-format="ddd, DD-MMM-YY"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <span
                class="w-full flex justify-center pr-0 xl:pr-[25%]"
                :class="{
                    'text-xl font-bold': getJobName.length > 25,
                    'title-1': getJobName.length > 15 && getJobName.length <= 25,
                    title: getJobName.length <= 15
                }"
            >
                {{ getJobName }}
            </span>

            <div v-if="workSchedule.id">
                <BaseTabs
                    v-model="currentTab"
                    :tabs="jobsAllocationTabs"
                    class="my-8 border-b border-grey-100"
                    @input="setTab"
                />

                <transition
                    name="fade"
                    mode="out-in"
                >
                    <component
                        :is="currentTab.component"
                        v-if="showComponent"
                        :work-schedule="workSchedule"
                        :model="{id: workSchedule.job.id, path: 'jobs'}"
                        @add-operative="loadWorkSchedule"
                        @reload="loadWorkSchedule(false)"
                    />
                    <div
                        v-else
                        class="my-3"
                    >
                        <QuestionList
                            read-only
                            :questions="questions"
                            @answer="handleAnswer"
                        />
                    </div>
                </transition>
            </div>
            <BaseCard
                v-else
                class="w-1/2 px-10 py-4 mx-auto mt-32 text-center bg-white title-2"
            >
                {{ $t('job-allocation.no-work-schedule') }}
                <br>
                {{ $t('job-allocation.select-new-date') }}
            </BaseCard>
        </div>
    </MainLayout>
</template>

<script>
import API from '@/api'
import MainLayout from '@/components/layout/MainLayout'
import Question from '@/components/forms/Question'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment-timezone'
import QuestionList from '@/components/forms/QuestionList'
import QuestionMixin from '@/mixins/QuestionMixin'

export default {
    name: 'JobAllocationDetailsView',
    components: {
        MainLayout,
        Question,
        QuestionList
    },
    mixins: [QuestionMixin],
    data () {
        return {
            showComponent: false,
            questions: [],
            currentTab: {},
            loading: false,
            workSchedule: {
                job: {}
            },
            job: {},
            selectedDate: ''
        }
    },
    computed: {
        ...mapGetters('FormsModule', ['getForms', 'getQuestions']),
        ...mapGetters('WorkSchedulesModule', ['getWorkSchedule']),
        ...mapGetters(['getGlobalDate']),
        ...mapGetters('AuthModule', ['getTimezone']),
        getDate: {
            get () {
                if (this.$route.query.date) {
                    return this.$route.query.date
                } else {
                    return this.selectedDate || this.getGlobalDate
                }
            },
            set (val) {
                this.selectedDate = ''
                this.SET_GLOBAL_DATE(val)
                this.$router.replace({ date: null }).catch(() => {})
                this.loadWorkSchedule().then(() => {
                    this.setTab(this.currentTab)
                })
            }
        },
        getJobName () {
            return this.job?.name || '-'
        },
        jobsAllocationTabs () {
            const tabs = []

            tabs.push({
                text: this.$i18n.t('job-allocation.operatives'),
                value: 'operatives',
                component: () =>
                    import(
                        '@/components/job-allocation/details/JobAllocationOperatives'
                    )
            })

            tabs.push({
                text: this.$i18n.t('job-allocation.timeSheet'),
                value: 'time_sheet',
                component: () =>
                    import(
                        '@/components/job-allocation/details/JobAllocationTimesheet'
                    )
            })

            tabs.push(...this.getForms)

            tabs.push(
                {
                    text: this.$i18n.t('job-allocation.instructions'),
                    value: 'instructions',
                    component: () =>
                        import(
                            '@/components/job-allocation/details/JobAllocationInstructions'
                        )
                },
                {
                    text: this.$i18n.t('signatures.client-signature'),
                    value: 'client_signatures',
                    component: () =>
                        import(
                            '@/components/team-lead/forms/ClientSignatures.vue'
                        )
                },
                {
                    text: this.$i18n.t('job-allocation.notes'),
                    value: 'notes',
                    component: () =>
                        import(
                            '@/components/job-allocation/details/JobAllocationNotes'
                        )
                },
                {
                    text: this.$i18n.t('job-allocation.attachments'),
                    value: 'attachments',
                    component: () => import('@/components/globals/BaseAttachments')
                }
            )

            return tabs
        }
    },
    async mounted () {
        const { date } = Object.assign({}, this.$route.query)
        if (date) {
            this.selectedDate = date
        } else {
            this.selectedDate = this.getGlobalDate
        }
        try {
            await this.loadWorkSchedule()
            this.currentTab = this.jobsAllocationTabs[0]
            this.setTab(this.currentTab)
            await this.setInputTypes()
            await this.setWorkSchedule()
        } catch (e) {
            this.$showAlert({
                message: e,
                type: 'error'
            })
        }
    },
    methods: {
        ...mapActions('FormsModule', [
            'setForms',
            'setQuestions',
            'setInputTypes'
        ]),
        ...mapMutations(['SET_GLOBAL_DATE']),
        ...mapActions('WorkSchedulesModule', ['setWorkSchedule']),
        handleAnswer ({ question, input }) {
            const targetQuestion = this.questions.find(
                (q) => q.id === question.id
            )
            targetQuestion.answer = input
        },
        async setTab (tab) {
            if (typeof tab.value === 'number') {
                this.loading = true
                this.showComponent = false
                try {
                    await this.setQuestions({
                        form_id: tab.value,
                        work_schedule_id: this.workSchedule.id
                    })
                    this.constructQuestionObject(
                        this.getQuestions(tab.value), true
                    )
                } catch (e) {
                    console.log(e)
                    this.$router.push('/')
                } finally {
                    this.loading = false
                }
            } else {
                this.showComponent = true
                this.currentTab.value = tab.value
            }
        },
        async loadWorkSchedule (load = true) {
            this.loading = load

            try {
                const workSchedules = await API.Models.getAll('workschedules', {
                    job: this.$route.params.id,
                    date: moment.tz(this.selectedDate || this.getGlobalDate, this.getTimezone).format('YYYY-MM-DD')
                })

                this.job = await API.Models.getModelByID(
                    'jobs',
                    this.$route.params.id
                )

                if (workSchedules.data.length) {
                    this.workSchedule = workSchedules.data[0]
                } else {
                    this.workSchedule = {}
                }

                await this.setForms({ status: 1, job: this.workSchedule?.job?.id, sort: 'order', direction: 'asc' })
            } catch (e) {
                this.$showAlert({
                    message: e,
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/jobs'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.job_status_types)
}

const getByDateRange = async (startDate, endDate) => {
    const url = `${baseURL}/bydate`

    const params = {
        start_date: startDate,
        end_date: endDate
    }

    const { data } = await AxiosConfig.get(url, { params })

    if (!data) return []

    return data
}

const addForm = async (id, payload) => {
    const url = `${baseURL}/${id}/add-form`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const removeForm = async (id, payload) => {
    const url = `${baseURL}/${id}/remove-form`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

export default {
    getByDateRange,
    getStatuses,
    addForm,
    removeForm
}

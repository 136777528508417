import AxiosConfig from '@/api/config'

const baseURL = '/reports'

const getWorkTimeSheetsReport = async (payload) => {
    const url = `${baseURL}/rope_hour_reports`

    const { data } = await AxiosConfig.get(url, payload)

    return data || []
}

const getJobNotesReport = async (payload) => {
    const url = `${baseURL}/notes_per_job`

    const { data } = await AxiosConfig.get(url, payload)

    return data || []
}

const getWorkAndSafetyReport = async (payload) => {
    const url = `${baseURL}/rope_hours_summary`

    const { data } = await AxiosConfig.get(url, payload)

    return data || []
}

const getAttendanceSheetReport = async (payload) => {
    const url = `${baseURL}/rope_hours_per_job`

    const { data } = await AxiosConfig.get(url, payload)

    return data || []
}

const getFormsLibraryReport = async (payload) => {
    const url = `${baseURL}/get_forms_data_for_job`

    const { data } = await AxiosConfig.get(url, payload)

    return data || []
}

export default {
    getWorkTimeSheetsReport,
    getJobNotesReport,
    getWorkAndSafetyReport,
    getAttendanceSheetReport,
    getFormsLibraryReport
}

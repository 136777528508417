import AxiosConfig from '@/api/config'

const baseURL = '/forms'

const getAllForms = async (params) => {
    const { data } = await AxiosConfig.get(baseURL, { params })

    return data
}

const getFormsTypes = async () => {
    const { data } = await AxiosConfig.get(`${baseURL}/types`)

    return data
}

const getForm = async (id) => {
    const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

    return data
}

const createForm = async (payload) => {
    const { data } = await AxiosConfig.post(baseURL, payload)

    if (!data) return {}

    return data
}

const updateForm = async (formID, payload) => {
    const url = `${baseURL}/${formID}`

    const { data } = await AxiosConfig.patch(url, payload)

    if (!data) return {}

    return data
}

const deleteForm = async (formID, payload) => {
    const url = `${baseURL}/${formID}`

    const { data } = await AxiosConfig.delete(url, payload)

    return data.data ? data.data : data
}

const getFormSubmissionSignatures = async (id) => {
    const { data } = await AxiosConfig.get(`form-submissions/${id}/signatures`)

    return data
}

const uploadSignature = async (id, payload) => {
    const { data } = await AxiosConfig.post(`form-submissions/${id}/signature`, payload)

    return data
}

const createFormSubmission = async (payload) => {
    const url = 'form-submissions'

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return {}

    return data
}

const updateFormSubmission = async (formID, payload) => {
    const url = `form-submissions/${formID}`

    const { data } = await AxiosConfig.patch(url, payload)

    if (!data) return {}

    return data
}

export default {
    getAllForms,
    createForm,
    updateForm,
    deleteForm,
    getForm,
    getFormsTypes,
    getFormSubmissionSignatures,
    uploadSignature,
    createFormSubmission,
    updateFormSubmission
}

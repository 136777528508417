// Imports
export default {
    BaseButton: () => import('@/components/globals/BaseButton'),
    BaseIcon: () => import('@/components/globals/BaseIcon'),
    BaseCard: () => import('@/components/globals/BaseCard'),
    BaseDateInput: () => import('@/components/globals/BaseDateInput'),
    BaseDatePicker: () => import('@/components/globals/BaseDatePicker'),
    BaseDivider: () => import('@/components/globals/BaseDivider'),
    BaseDropdown: () => import('@/components/globals/BaseDropdown'),
    BaseDropdownItem: () => import('@/components/globals/BaseDropdownItem'),
    BaseExpandable: () => import('@/components/globals/BaseExpandable'),
    BaseInput: () => import('@/components/globals/BaseInput'),
    BaseLoadingSpinner: () => import('@/components/globals/BaseLoadingSpinner'),
    BaseModal: () => import('@/components/globals/BaseModal'),
    BaseModelTable: () => import('@/components/globals/BaseModelTable'),
    BaseNotificationPeriod: () =>
        import('@/components/globals/BaseNotificationPeriod'),
    BasePagination: () => import('@/components/globals/BasePagination'),
    BaseRadioButton: () => import('@/components/globals/BaseRadioButton'),
    BaseSelect: () => import('@/components/globals/BaseSelect'),
    BaseSingleSelect: () => import('@/components/globals/BaseSingleSelect'),
    BaseTabs: () => import('@/components/globals/BaseTabs'),
    BaseTable: () => import('@/components/globals/BaseTable'),
    BaseTag: () => import('@/components/globals/BaseTag'),
    BaseTextarea: () => import('@/components/globals/BaseTextarea'),
    BaseTimeline: () => import('@/components/globals/BaseTimeline'),
    BaseUpload: () => import('@/components/globals/BaseUpload'),
    BaseYesNoCheckbox: () => import('@/components/globals/BaseYesNoCheckbox'),
    BasePdfConverter: () => import('@/components/globals/BasePdfConverter'),
    TableSorting: () => import('@/components/globals/TableSorting'),
    HeaderSortable: () => import('@/components/globals/HeaderSortable'),
    ArrowUp: () => import('@/components/globals/icons/ArrowUp'),
    ArrowDown: () => import('@/components/globals/icons/ArrowDown'),
    ActiveFilters: () => import('@/components/globals/ActiveFilters'),
    BaseBackToTop: () => import('@/components/globals/BaseBackToTop'),
    BaseBarcodeScanner: () => import('@/components/globals/BaseBarcodeScanner'),
    BaseExportLoading: () => import('@/components/globals/BaseExportLoading'),
    BaseRichEditor: () => import('@/components/globals/BaseRichEditor'),
    BaseDynamicChecklist: () => import('@/components/globals/BaseDynamicChecklist'),
    BaseMediaUpload: () => import('@/components/globals/BaseMediaUpload'),
    InfiniteScrollTrigger: () => import('@/components/globals/InfiniteScrollTrigger'),
    BaseOverlay: () => import('@/components/globals/BaseOverlay'),
    BasePanelActions: () => import('@/components/globals/BasePanelActions')
}

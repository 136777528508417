import API from '@/api'
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        forms: [],
        questions: {},
        answers: [],
        inputTypes: []
    },
    getters: {
        getForms: (state) => state.forms,
        getQuestions: (state) => (id) => state.questions[id],
        getInputTypes: (state) => state.inputTypes,
        getInputType: (state) => (typeId) =>
            state.inputTypes.find(({ id }) => id === typeId)
    },
    mutations: {
        SET_FORMS (state, forms) {
            state.forms = forms.map((f) => ({
                text: f.name,
                value: f.id
            }))
        },
        SET_QUESTIONS (state, { id, data }) {
            state.questions[id] = data
        },
        SET_QUESTION_TYPES (state, types) {
            state.inputTypes = types
        },
        SET_ANSWER (state, answer) {
            const existingAnswer = state.answer.find(
                (ans) =>
                    ans.work_schedule_id === answer.work_schedule_id &&
                    ans.form_question_id === answer.form_question_id
            )

            if (!existingAnswer) {
                state.answers.push(answer)
            } else {
                Object.assign(existingAnswer, answer)
            }
        }
    },
    actions: {
        async setForms ({ commit }, params = {}) {
            if (Object.prototype.hasOwnProperty.call(params, 'autocomplete')) {
                if (params.autocomplete.length < 2) {
                    commit('SET_FORMS', [])
                    return
                }
            }

            const { data } = await API.Models.getAll('forms', params)
            commit('SET_FORMS', data)
        },
        async setQuestions (
            { commit, state },
            params = { overRideCache: false }
        ) {
            if (state.questions[params.form_id] && params.overRideCache === false) return []
            const { data } = await API.Models.getAll(
                'form-questions-answers',
                params
            )
            commit('SET_QUESTIONS', { id: params.form_id, data })

            return data
        },
        async setInputTypes ({ commit, state }) {
            if (!_.isEmpty(state.inputTypes)) return
            const types = await API.Questions.getInputTypes()
            commit('SET_QUESTION_TYPES', types)
        },
        async createAnswer ({ commit }, params) {
            const answer = await API.Answers.createAnswer(params)
            commit('SET_QUESTION_TYPES', answer)
        },
        async updateMultipleQuestions (context, payload) {
            await API.Questions.updateMultipleQuestions(payload)
        }
    }
}

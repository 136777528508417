import ClientsRoutes from './ClientsRoutes'
import InvoicesRoutes from './InvoicesRoutes'
import PaymentsRoutes from './PaymentsRoutes'
import InventoryRoutes from './InventoryRoutes'
import JobAllocationRoutes from './JobAllocationRoutes'
import JobsRoutes from './JobsRoutes'
import OperativesRoutes from './OperativesRoutes'
import UsersRoutes from './UsersRoutes'
import FormsRoutes from './FormsRoutes'
import ReportsRoutes from './ReportsRoutes'
import ExpensesRoutes from './ExpensesRoutes'
import TenantRoutes from './TenantRoutes'
import CategoryRoutes from './CategoryRoutes'

export default [
    {
        path: '/admin',
        component: () => import('@/views/AdminRouter'),
        meta: { requiresAdmin: true },
        children: [
            ...TenantRoutes,
            ...ClientsRoutes,
            ...InvoicesRoutes,
            ...PaymentsRoutes,
            ...InventoryRoutes,
            ...JobAllocationRoutes,
            ...JobsRoutes,
            ...OperativesRoutes,
            ...UsersRoutes,
            ...FormsRoutes,
            ...ReportsRoutes,
            ...ExpensesRoutes,
            ...CategoryRoutes,
            {
                path: '/admin/dashboard',
                name: 'DashboardView',
                component: () => import('@/views/admin/DashboardView'),
                meta: {
                    title: 'My Abseil - Dashboard',
                    requiresAuth: true,
                    requiresAdmin: true
                }
            },
            {
                path: '/admin/calendar',
                name: 'CalendarView',
                component: () => import('@/views/admin/CalendarView'),
                meta: {
                    title: 'My Abseil - Calendar',
                    requiresAuth: true,
                    requiresAdmin: true
                }
            },
            {
                path: '/admin/security',
                name: 'SecurityView',
                component: () => import('@/views/auth/SecurityView'),
                meta: { requiresAuth: true, requiresAdmin: true }
            },
            {
                path: '/admin/expired-items',
                name: 'ExpiredItemsView',
                component: () => import('@/views/admin/items/ExpiredItemsView'),
                meta: { requiresAuth: true, requiresAdmin: true }
            },
            {
                path: '/admin/resources',
                name: 'ResourcesView',
                component: () => import('@/views/shared/ResourcesView'),
                meta: { requiresAuth: true, requiresAdmin: true }
            },
            {
                path: '/admin/notifications',
                name: 'AdminNotificationsView',
                component: () => import('@/views/shared/NotificationsView'),
                meta: { requiresAuth: true, requiresAdmin: true }
            },
            {
                path: '/admin/tags',
                name: 'AdminTagsView',
                component: () => import('@/views/shared/TagsView'),
                meta: { requiresAuth: true, requiresAdmin: true }
            }
        ]
    }
]

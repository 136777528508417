import DriversRoutes from './DriversRoutes'

export default [
    {
        path: '/drivers',
        component: () => import('@/views/DriverRouter'),
        meta: { requiresDriver: true },
        redirect: { name: 'DriversDashboardView' },
        children: [...DriversRoutes]
    }
]

import AxiosConfig from '@/api/config'

const baseURL = '/form-questions-answers'

const getAnswerById = async (id) => {
    const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

    return data
}

const createAnswer = async (params) => {
    const { data } = await AxiosConfig.post(baseURL, params)

    return data
}

const updateMultipleAnswers = async (payload) => {
    const url = `${baseURL}/update-multiple`

    const { data } = await AxiosConfig.post(url, payload)

    return data
}

const deleteFormQuestion = async (answerId, mediaId) => {
    const url = `${baseURL}/${answerId}/delete_media/${mediaId}`

    const { data } = await AxiosConfig.post(url)

    return data
}

const getMedia = async (mediaId, mediaName = 'download') => {
    const url = `/download-media/${mediaId}`

    const { data } = await AxiosConfig.get(url, {
        responseType: 'blob'
    })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', mediaName)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

export default {
    updateMultipleAnswers,
    getAnswerById,
    createAnswer,
    deleteFormQuestion,
    getMedia
}

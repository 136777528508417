import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/expenses'

const getExpensesCategories = async () => {
    const apiUrl = `${baseURL}/all-categories`
    return await fetchAndStoreData(apiUrl, db.expenses_categories)
}

const getCurrencyTypes = async () => {
    const apiUrl = `${baseURL}/currencies-types`
    return await fetchAndStoreData(apiUrl, db.currencies)
}

const getAllExpenses = async (params) => {
    const { data } = await AxiosConfig.get(baseURL, { params })

    return data
}

const getExpenseByID = async (expenseID) => {
    const url = `${baseURL}/${expenseID}`

    const { data } = await AxiosConfig.get(url)

    return data.data ? data.data : data
}

const createExpense = async (payload) => {
    const { data } = await AxiosConfig.post(baseURL, payload)

    return data.data ? data.data : data
}

const updateExpense = async (expenseID, payload) => {
    const url = `${baseURL}/${expenseID}`

    const { data } = await AxiosConfig.path(url, payload)

    return data.data ? data.data : data
}

const deleteExpense = async (expenseID, payload) => {
    const url = `${baseURL}/${expenseID}`

    const { data } = await AxiosConfig.delete(url, payload)

    return data.data ? data.data : data
}

const getExpenseAttachments = async (expenseID) => {
    const url = `${baseURL}/${expenseID}/attachments`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const uploadExpenseAttachment = async (expenseID, payload) => {
    const url = `${baseURL}/${expenseID}/attachfile`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

export default {
    getExpensesCategories,
    getAllExpenses,
    getExpenseByID,
    createExpense,
    updateExpense,
    deleteExpense,
    getExpenseAttachments,
    uploadExpenseAttachment,
    getCurrencyTypes
}

// Operatives Routes
export default [
    {
        path: 'operatives',
        name: 'OperativesView',
        component: () => import('@/views/admin/operatives/OperativesView'),
        meta: {
            title: 'My Abseil - Operatives',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'operatives/details/:id',
        name: 'AdminOperativesDetailsView',
        component: () =>
            import('@/views/admin/operatives/OperativesDetailsView'),
        meta: {
            title: 'My Abseil - Operatives',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'operatives/edit/:id',
        name: 'OperativesEditView',
        component: () => import('@/views/admin/operatives/OperativesEditView'),
        meta: {
            title: 'My Abseil - Operatives',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

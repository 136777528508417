<template>
    <div
        id="app"
    >
        <div>
            <InstallationBanner
                v-if="deferredPrompt"
                @install="handleInstall"
                @close="handleClose"
            />
            <router-view />
        </div>
        <div class="main-notifications">
            <BaseNotifyAlert
                v-for="alert in getAlerts"
                :key="alert.id"
                :alert="alert"
            />
        </div>
        <BaseConfirmation />
        <BaseDocumentPreview />
        <BaseLoadingSpinner />
        <BaseBackToTop v-if="areAllowedPages && showBackToTopButton" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import API, { getAppVersion } from '@/api'
import PWAMixin from './mixins/PWAMixin'
import InstallationBanner from './components/globals/InstallationBanner.vue'
import Cookies from 'js-cookie'

export default {
    components: { InstallationBanner },
    mixins: [PWAMixin],
    data () {
        return {
            showBackToTopButton: false,
            deferredPrompt: null
        }
    },
    computed: {
        ...mapGetters('BaseAlertModule', ['getAlerts']),
        ...mapGetters('AuthModule', ['getUserRole', 'isUserLoggedIn', 'getLoginStatus', 'getUserId']),
        isInStandaloneMode () {
            return ('standalone' in window.navigator) && (window.navigator.standalone)
        },
        isIos () {
            const userAgent = window.navigator.userAgent.toLowerCase()
            return /iphone|ipad|ipod/.test(userAgent)
        },
        areAllowedPages () {
            return ['ReportsView', 'AdminFormsDetailsView'].includes(this.$route.name)
        }
    },
    watch: {
        getLoginStatus: {
            handler (val) {
                if (val) {
                    this.getAppSettings()
                    this.fetchNotifications()
                }
            },
            immediate: true,
            deep: true
        }
    },
    created () {
        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault()
            // Stash the event so it can be triggered later.
            if (Cookies.get('myabseil-add-to-home-screen-install') === undefined) {
                this.deferredPrompt = e
            }
        })
        window.addEventListener('appinstalled', () => {
            this.deferredPrompt = null
        })
    },

    mounted () {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js').then(reg => {
                console.log('Registration succesful, scope: ' + reg.scope)
            })
                .catch(err => {
                    console.log(err)
                })
        }
        if (this.isIos && !this.isInStandaloneMode) {
            console.log('is ios...')
        }

        if (this.isUserLoggedIn) {
            this.getAppSettings()
            this.getUser()
        }
        this.onWindowFocus()

        window.addEventListener('scroll', this.updateShowBackToTopButton)
        window.addEventListener('focus', this.onWindowFocus)
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.updateShowBackToTopButton)
        window.removeEventListener('focus', this.onWindowFocus)
    },
    methods: {
        ...mapActions('ExpensesModule', [
            'setCurrencyTypes',
            'setExpenseCategories'
        ]),
        ...mapActions('OperativesModule', [
            'setOperativesLevels',
            'setOperativesStatuses',
            'setOperativesTypes'
        ]),
        ...mapActions('InventoryModule', [
            'setInventoryStatuses',
            'setInventoryCategories',
            'setInventoryMainTypes'
        ]),
        ...mapActions('DashboardModule', ['setNotifications']),
        ...mapActions('WorkSchedulesModule', ['setWorkScheduleStatuses']),
        ...mapActions('AuthModule', ['logoutUser', 'fetchUser']),
        ...mapActions('UsersModule', ['setRoles']),
        ...mapActions('InspectionsModule', ['setInspectionsStatuses']),
        ...mapActions('JobsModule', ['setJobs', 'setJobStatuses', 'setJobTypes']),
        ...mapActions('InvoicesModule', ['setInvoicesStatuses']),
        ...mapMutations('NavigationModule', ['SET_BACK_TO_TOP_BUTTON']),
        ...mapActions('ResourceModule', ['setDocumentTypes', 'setAttachmentTypes']),
        ...mapActions('DashboardModule', ['setVersion']),
        async getUser () {
            try {
                if (this.getUserId) {
                    await this.fetchUser(this.getUserId)
                }
            } catch (e) {
                console.log(e)
            }
        },
        async fetchNotifications () {
            try {
                const { meta: { total = 0 } = {} } = await API.Dashboard.getNotifications({ type: 'all' })
                this.setNotifications(total)
            } catch (e) {
                console.log(e)
            }
        },
        async getAppSettings () {
            await this.setCurrencyTypes()
            await this.setExpenseCategories()
            await this.setOperativesLevels()
            await this.setOperativesStatuses()
            await this.setInventoryCategories()
            await this.setInventoryMainTypes()
            await this.setWorkScheduleStatuses()
            await this.setInspectionsStatuses()
            await this.setJobStatuses()
            await this.setInventoryStatuses()
            await this.setInvoicesStatuses()
            await this.setRoles()
            await this.setDocumentTypes()
            await this.setOperativesTypes()
            await this.setJobTypes()
            await this.setAttachmentTypes()
        },
        $_scrolledPercentage () {
            return (window.scrollY / (document.body.offsetHeight - window.innerHeight)) * 100
        },
        updateShowBackToTopButton () {
            this.showBackToTopButton = this.$_scrolledPercentage() >= 10
            this.SET_BACK_TO_TOP_BUTTON(this.showBackToTopButton)
        },
        async handleInstall () {
            this.deferredPrompt.prompt()
        },
        async handleClose () {
            Cookies.set('myabseil-add-to-home-screen-install', null, { expires: 15 })
            this.deferredPrompt = null
        },
        async onWindowFocus () {
            this.$store.commit('SET_DISABLE_LOADER_TO_STATE', true)
            const version = await getAppVersion()
            this.setVersion(version)
            this.$store.commit('SET_DISABLE_LOADER_TO_STATE', false)
        }
    }
}
</script>

<style lang="sass">
#id
    position: relative
.main-notifications
    left: 50%
    transform: translateX(-50%)
    z-index: 5000000
    @apply fixed flex flex-col justify-center mx-auto top-0
</style>

export default [
    {
        path: 'dashboard',
        name: 'DriversDashboardView',
        component: () => import('@/views/driver/DriversDashboardView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'jobs',
        name: 'DriversJobsView',
        component: () => import('@/views/driver/jobs/DriversJobsView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'jobs/details/:id',
        name: 'DriversJobView',
        component: () => import('@/views/driver/jobs/DriversJobView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'inventory',
        name: 'DriversInventoryView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'inventory/details/:id',
        name: 'DriversInventoryDetailView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryDetailView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'inventory/edit/:id',
        name: 'DriversInventoryEditView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryEditView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'expenses',
        name: 'DriversExpensesView',
        component: () => import('@/views/driver/expenses/DriversExpensesView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'expenses/details/:id',
        name: 'DriversExpensesDetailView',
        component: () => import('@/views/expenses/ExpensesDetailView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'expenses/edit/:id',
        name: 'ExpensesEditView',
        component: () => import('@/views/expenses/ExpensesEditView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'operatives',
        name: 'DriversOperativesView',
        component: () =>
            import('@/views/driver/operatives/DriversOperativesView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'operatives/details/:id',
        name: 'DriversOperativesDetailsView',
        component: () =>
            import('@/views/driver/operatives/DriversOperativeView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'inventory/multiple',
        name: 'DriversMultipleInventoryView',
        component: () =>
            import('@/views/shared/MultipleInventoryView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'free-forms/:tab?',
        name: 'DriversFreeFormsView',
        component: () =>
            import('@/views/driver/DriversFreeFormsView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id?/edit',
        name: 'DriverFreeFormsFill',
        component: () => import('@/views/driver/DriverFreeFormsFill'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id/details',
        name: 'DriverFreeFormsDetailsView',
        component: () => import('@/views/driver/DriverFreeFormsDetailsView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'resources',
        name: 'DriverResourcesView',
        component: () => import('@/views/driver/DriverResourcesView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'settings',
        name: 'DriverSettings',
        component: () => import('@/views/team-lead/TeamLeadSettingsView'),
        meta: { requiresAuth: true, requiresDriver: true }
    },
    {
        path: 'settings/edit/',
        name: 'DriverEditUserView',
        component: () => import('@/views/team-lead/EditUserView'),
        meta: {
            requiresAuth: true,
            requiresDriver: true
        }
    },
    {
        path: '/notifications',
        name: 'DriverNotificationsView',
        component: () => import('@/views/shared/NotificationsView'),
        meta: { requiresAuth: true, requiresDriver: true }
    }
]

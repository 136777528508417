import AxiosConfig from '@/api/config'
import { fetchAndStoreData } from '.'
import db from '@/offline/indexedDBHelper'

const baseURL = '/operatives'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`

    return await fetchAndStoreData(url, db.operatives_statuses)
}

const getLevels = async () => {
    const url = `${baseURL}/level-types`

    return await fetchAndStoreData(url, db.operatives_levels)
}

const checkIn = async (payload) => {
    const url = `${baseURL}/checkin`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const checkOut = async (payload) => {
    const url = `${baseURL}/checkout`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const switchTeamLead = async (payload) => {
    const url = `${baseURL}/checkout`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const addSignature = async (payload) => {
    const url = '/operative-signature'

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return []

    return data
}

const getSignatures = async (payload) => {
    const url = '/operative-signature/'

    const { data } = await AxiosConfig.get(url, { params: payload })

    if (!data) return []

    return data
}

export default {
    checkIn,
    checkOut,
    getLevels,
    getStatuses,
    switchTeamLead,
    addSignature,
    getSignatures
}

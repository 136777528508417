import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        inventory: [],
        inventoryMainTypes: [],
        inventoryCategories: [],
        inventoryStatusTypes: []
    },
    getters: {
        getInventory: (state) => state.inventory,
        getInventoryMainTypes: (state) => state.inventoryMainTypes,
        getInventoryCategories: (state) => state.inventoryCategories,
        getInventoryStatusTypes: (state) => state.inventoryStatusTypes
    },
    mutations: {
        SET_INVENTORY (state, inventory) {
            state.inventory = inventory
        },
        SET_INVENTORY_MAIN_TYPES (state, mainTypes) {
            state.inventoryMainTypes = mainTypes
        },
        SET_INVENTORY_CATEGORIES (state, categories) {
            state.inventoryCategories = categories
        },
        SET_INVENTORY_STATUS_TYPES (state, statusTypes) {
            state.inventoryStatusTypes = statusTypes
        },
        DELETE_CATEGORY (state, id) {
            state.inventoryCategories = state.inventoryCategories.filter(
                (cat) => cat.id !== id
            )
        }
    },
    actions: {
        deleteCategory ({ commit }, id) {
            API.Categories.deleteCategory('inventoryitems', id).then(() =>
                commit('DELETE_CATEGORY', id)
            )
        },
        async setInventory ({ commit }, params) {
            const inventory = await API.Models.getAll('inventoryitems', params)
            commit('SET_INVENTORY', inventory.data)
        },
        async setInventoryMainTypes ({ commit, state }) {
            if (!_.isEmpty(state.inventoryMainTypes)) return
            const mainTypes = await API.Inventory.getMainTypes()
            commit('SET_INVENTORY_MAIN_TYPES', mainTypes)
        },
        async setInventoryCategories ({ commit }) {
            // if (!_.isEmpty(state.inventoryCategories)) return
            const categories = await API.Tags.getTags({
                type: 'Inventory'
            })

            // const categories = await API.Inventory.getTags()
            commit('SET_INVENTORY_CATEGORIES', categories?.data || [])
        },
        async setInventoryStatuses ({ commit, state }) {
            if (!_.isEmpty(state.inventoryStatusTypes)) return
            const statusTypes = await API.Inventory.getStatusTypes()
            commit('SET_INVENTORY_STATUS_TYPES', statusTypes)
        }
    }
}

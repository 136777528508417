import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        invoicesStatuses: []
    },
    getters: {
        getInvoicesStatuses: (state) => state.invoicesStatuses
    },
    mutations: {
        SET_INVOICES_STATUSES (state, invoicesStatuses) {
            state.invoicesStatuses = invoicesStatuses
        }
    },
    actions: {
        async setInvoicesStatuses ({ commit, state }) {
            if (!_.isEmpty(state.invoicesStatuses)) return
            const invoicesStatuses = await API.Invoices.getStatuses()
            commit('SET_INVOICES_STATUSES', invoicesStatuses)
        }
    }
}

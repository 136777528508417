import AxiosConfig from '@/api/config'

const baseURL = '/dashboard'

const getStats = async () => {
    const url = `${baseURL}/stats`

    const { data } = await AxiosConfig.get(url)

    return data.data ? data.data : data
}

const getNotifications = async ({ type = 'all', page = 1 }) => {
    const url = `${baseURL}/notifications?type=${type}&page=${page}`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const clearNotifications = async () => {
    const url = `${baseURL}/notifications/markread`

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

const clearNotification = async (modelID) => {
    const url = `${baseURL}/notifications/${modelID}/markread`

    const { data } = await AxiosConfig.post(url)

    return data.data ? data.data : data
}

export default {
    clearNotifications,
    getNotifications,
    getStats,
    clearNotification
}

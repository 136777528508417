export default [
    {
        path: 'expenses',
        name: 'AdminExpensesView',
        component: () => import('@/views/admin/expenses/ExpensesView'),
        meta: {
            title: 'My Abseil - Expenses',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'expenses/details/:id',
        name: 'AdminExpensesDetailView',
        component: () => import('@/views/expenses/ExpensesDetailView'),
        meta: {
            title: 'My Abseil - Expenses',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'expenses/edit/:id',
        name: 'AdminExpensesEditView',
        component: () => import('@/views/expenses/ExpensesEditView'),
        meta: {
            title: 'My Abseil - Expenses',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

import AxiosConfig from '@/api/config'

const getMenuItems = async () => {
    const url = '/menuitems'

    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
}

export default {
    getMenuItems
}

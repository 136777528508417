import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Components from '@/components/globals'
import Filters from '@/filters'
import TailwindMixin from '@/mixins/TailwindMixin'
import VueDismiss from '@/mixins/VueDismiss'
import i18n from './locales'
import { validationMixin } from 'vuelidate/src'
import BaseNotify from '@/plugins/BaseNotifications/BaseNotify'
import BaseConfirmation from '@/plugins/BaseConfirmation/BaseConfirmation'
import BaseDocumentPreview from '@/plugins/BaseDocumentPreview/BaseDocumentPreview'
import VueSignaturePad from 'vue-signature-pad'
import VueEasyLightbox from 'vue-easy-lightbox'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import FloatingVue from 'floating-vue'

import 'vue-js-modal/dist/styles.css'
import '@/assets/css/tailwind.css'
import '@spatie/media-library-pro-styles/dist/styles.css'
import 'floating-vue/dist/style.css'

import './registerServiceWorker'

Vue.config.productionTip = false

Object.keys(Components).forEach((key) => {
    Vue.component(key, Components[key])
})

Object.keys(Filters).forEach((key) => {
    Vue.filter(key, Filters[key])
})

Vue.mixin(TailwindMixin)
Vue.mixin(VueDismiss)
Vue.mixin(validationMixin)

Vue.use(BaseNotify, { store })
Vue.use(BaseConfirmation)
Vue.use(BaseDocumentPreview)
Vue.use(VueSignaturePad)
Vue.use(VueEasyLightbox)
Vue.use(VModal)
Vue.use(FloatingVue)

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App)
}).$mount('#app')

import AxiosConfig from '@/api/config'

const baseURL = '/instructions'

const getAllInstructions = async (workScheduleID) => {
    const url = `${baseURL}?work_schedule_id=${workScheduleID}`

    const { data } = await AxiosConfig.get(url)

    return data || []
}

const getAllInstructionsFilter = async (filters) => {
    const url = `${baseURL}`

    const { data } = await AxiosConfig.get(url, { params: filters })

    return data || []
}

const createInstruction = async (workScheduleID, createInstructionsPayload) => {
    const { title, body, recipients } = createInstructionsPayload

    const payload = {
        work_schedule_id: workScheduleID,
        title,
        body,
        recipients
    }

    const { data } = await AxiosConfig.post(baseURL, payload)

    return data || []
}

const updateInstructionStatus = async (instructionID, status) => {
    const url = `${baseURL}/${instructionID}/change-status?status=${status}`

    const { data } = await AxiosConfig.post(url)

    return data || []
}

export default {
    getAllInstructions,
    getAllInstructionsFilter,
    createInstruction,
    updateInstructionStatus
}

export default [
    {
        path: 'reports',
        name: 'ReportsView',
        component: () => import('@/views/admin/reports/ReportsView'),
        meta: {
            title: 'My Abseil - Reports',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'forms-data',
        name: 'ReportsFormDataView',
        component: () => import('@/components/reports/ReportsFormLibrary'),
        meta: {
            title: 'My Abseil - Forms Data',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

import API from '@/api'

export default {
    namespaced: true,
    state: {
        clients: []
    },
    getters: {
        getClients: (state) => state.clients
    },
    mutations: {
        SET_CLIENTS (state, clients) {
            state.clients = clients
        }
    },
    actions: {
        async setClients ({ commit }, params = {}) {
            if (Object.prototype.hasOwnProperty.call(params, 'autocomplete')) {
                const { autocomplete } = params

                if (autocomplete.length < 2) {
                    commit('SET_CLIENTS', [])
                    return
                }
            }

            const clients = await API.Models.getAll('clients', params)
            commit('SET_CLIENTS', clients.data)
        }
    }
}

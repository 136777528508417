// eslint-disable-next-line
const plugin = require('tailwindcss/plugin')

module.exports = {
    content: [
        './src/**/*.html',
        './src/**/*.vue'
    ],
    theme: {
        extend: {
            spacing: {
                xs: '8px',
                s: '16px',
                m: '24px',
                l: '32px',
                xl: '40px',
                '2x-l': '48px'
            },
            colors: {
                blue: {
                    DEFAULT: '#3D67FF',
                    darker: '#D5DFFF',
                    light: '#F9FAFC',
                    100: '#E4F7FF',
                    200: '#61D2FF',
                    500: '#3D67FF'
                },
                turquoise: {
                    DEFAULT: '#61D2FF',
                    light: '#E4F7FF'
                },
                green: {
                    DEFAULT: '#39DEB7',
                    light: '#DEF7EF'
                },
                grey: {
                    100: '#F6F9FE',
                    300: '#F5F6F8',
                    400: '#E8E9EE',
                    600: '#DEDFE7',
                    700: '#CFD3E3',
                    800: '#9196AF'
                },
                purple: {
                    100: '#F8F9FE'
                },
                black: '#23282D',
                white: '#FFFFFF',
                success: '#6FCF97',
                link: '#596FD0',
                danger: {
                    DEFAULT: '#FF6464',
                    light: '#FF6262'
                },
                transparent: 'transparent',
                shadow: '#23282D4C',
                background: 'rgb(247, 249, 251)',
                input: {
                    DEFAULT: '#f8f9fa',
                    focus: '#f1f3f4'
                }
            }
        },
        borderRadius: {
            none: '0',
            sm: '0.125rem',
            DEFAULT: '4px',
            md: '0.375rem',
            lg: '8px',
            full: '50%',
            large: '12px',
            pill: '9999px'
        },
        boxShadow: {
            sm: '0px 5px 20px rgba(3, 16, 32, 0.05)',
            DEFAULT:
                '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            md:
                '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            lg:
                '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            xl:
                '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
            '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
            none: 'none'
        }
    },
    variants: {
        extend: {
            borderWidth: ['last'],
            paddingBottom: ['last'],
            marginBottom: ['last'],
            backgroundColor: ['even', 'odd'],
            visibility: ['group-hover', 'group-focus', 'hover', 'focus']
        }
    },
    plugins: [
        plugin(function ({ addUtilities }) {
            const newUtilities = {
                '.title': {
                    fontSize: '35px',
                    fontWeight: 700,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.title-1': {
                    fontSize: '32px',
                    fontWeight: 700,
                    fontFamily: 'Gilroy, DM Sans, sans-serif'
                },
                '.title-2': {
                    fontSize: '22px',
                    fontWeight: 700,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.title-3': {
                    fontSize: '16px',
                    fontWeight: 700,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.question-title': {
                    fontSize: '17px',
                    lineHeight: '25px',
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.heading': {
                    fontSize: '28px',
                    fontWeight: 700,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.heading-1': {
                    fontSize: '24px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.body-1': {
                    fontSize: '16px',
                    fontWeight: 500,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.body-2': {
                    fontSize: '18px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.body-3': {
                    fontSize: '14px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.body-3-bolder': {
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'DM Sans, sans-serif'
                },
                '.caption': {
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'DM Sans, sans-serif'
                }
            }

            addUtilities(newUtilities, ['responsive', 'hover'])
        })
    ]
}

import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        users: [],
        roles: [],
        timezones: [],
        days: [
            { name: 'Sunday', id: 0 },
            { name: 'Monday', id: 1 },
            { name: 'Tuesday', id: 2 },
            { name: 'Wednesday', id: 3 },
            { name: 'Thursday', id: 4 },
            { name: 'Friday', id: 5 },
            { name: 'Saturday', id: 6 }
        ]
    },
    getters: {
        getUsers: (state) => state.users,
        getRoles: (state) => state.roles,
        getTimeZones: (state) => state.timezones,
        getWeekDays: (state) => state.days,
        getDayOfWeek: (state) => (id) =>
            state.days.find((day) => day.id === id).name
    },
    mutations: {
        SET_USERS (state, users) {
            state.users = users
        },
        SET_TIMEZONES (state, timezones) {
            state.timezones = timezones
        },
        SET_ROLES (state, roles) {
            state.roles = roles
        }
    },
    actions: {
        async setUsers ({ commit }) {
            const users = await API.Models.getAll('users')
            commit('SET_USERS', users)
        },
        async setRoles ({ commit, state }) {
            if (!_.isEmpty(state.roles)) return
            const roles = await API.Models.getAll('roles')
            commit('SET_ROLES', roles)
        },
        async setTimeZones ({ commit }) {
            const timezones = await API.User.getTimeZones()
            commit('SET_TIMEZONES', timezones)
        }
    }
}

export default [
    {
        path: 'tenant/details/:id',
        name: 'TenantDetailsView',
        component: () => import('@/views/admin/tenant/TenantDetailsView'),
        meta: {
            title: 'My Abseil - Tenant',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'tenant/edit/:id',
        name: 'TenantEditView',
        component: () => import('@/views/admin/tenant/TenantEditView'),
        meta: {
            title: 'My Abseil - Tenant',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]

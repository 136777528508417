import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/form-questions'

const getAllQuestions = async (params) => {
    const { data } = await AxiosConfig.get(baseURL, { params })

    if (!data) return {}

    return data
}

const updateMultipleQuestions = async (payload) => {
    const url = `${baseURL}/update-multiple`

    const { data } = await AxiosConfig.post(url, payload)

    if (!data) return {}

    return data
}

const createQuestion = async (payload) => {
    const { data } = await AxiosConfig.post(baseURL, payload)

    if (!data) return {}

    return data
}

const updateQuestion = async (formID, payload) => {
    const url = `${baseURL}/${formID}`

    const { data } = await AxiosConfig.patch(url, payload)

    if (!data) return {}

    return data
}

const deleteQuestion = async (formID) => {
    const url = `${baseURL}/${formID}`

    const { data } = await AxiosConfig.delete(url)

    if (!data) return {}

    return data.data ? data.data : data
}

const getInputTypes = async () => {
    const url = `${baseURL}/input-types`
    return await fetchAndStoreData(url, db.form_questions_input_types)
}

export default {
    getAllQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    getInputTypes,
    updateMultipleQuestions
}

import AxiosConfig from '@/api/config'

const loginUser = async (loginPayload) => {
    const url = '/login'

    const { data } = await AxiosConfig.post(url, loginPayload)
    if (!data) return {}
    return data.data
}

const fetchUser = async (id) => {
    const url = `/users/${id}`

    const { data } = await AxiosConfig.get(url)
    if (!data) return {}
    return data
}

export default {
    fetchUser,
    loginUser
}

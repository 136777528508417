var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('media-library-renderless',{ref:"mediaLibraryRenderless",attrs:{"initial-value":_vm.initialValue,"validation-errors":_vm.validationErrors,"route-prefix":_vm.routePrefix,"validation-rules":_vm.validationRules,"translations":_vm.translations,"before-upload":_vm.beforeUpload,"after-upload":_vm.afterUpload,"name":_vm.name,"max-items":_vm.maxItems,"max-size-for-preview-in-bytes":_vm.maxSizeForPreviewInBytes,"vapor":_vm.vapor,"vapor-signed-storage-url":_vm.vaporSignedStorageUrl,"upload-domain":_vm.uploadDomain,"with-credentials":_vm.withCredentials,"headers":_vm.headers},on:{"changed":function($event){return _vm.$emit('change', $event)},"is-ready-to-submit-change":function($event){return _vm.$emit('is-ready-to-submit-change', $event)},"has-uploads-in-progress-change":function($event){return _vm.$emit('has-uploads-in-progress-change', $event)}},scopedSlots:_vm._u([{key:"default",fn:function({
                state,
                removeMedia,
                getCustomPropertyInputProps,
                getCustomPropertyInputListeners,
                getCustomPropertyInputErrors,
                getNameInputProps,
                getNameInputListeners,
                getNameInputErrors,
                getDropZoneProps,
                getDropZoneListeners,
                getFileInputProps,
                getFileInputListeners,
                getErrors,
                clearObjectErrors,
                clearInvalidMedia,
            }){return _c('div',{},[_c('icons'),_c('div',{class:`media-library media-library-multiple ${state.media?.length == 0 ? 'media-library-empty' : 'media-library-filled'
                } ${_vm.sortable && 'media-library-sortable'}`},[_c('list-errors',{attrs:{"invalid-media":state.invalidMedia,"top-level-errors":_vm.validationErrors[_vm.name]},on:{"cleared":function($event){return clearInvalidMedia()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(state.media && state.media.length),expression:"state.media && state.media.length"},{name:"dragula",rawName:"v-dragula",value:(_vm.sortable ? state.media : undefined),expression:"sortable ? state.media : undefined"}],staticClass:"media-library-items",attrs:{"bag":_vm.sortable ? _vm.dragulaBagName : undefined}},_vm._l((state.media),function(object){return _c('div',{key:object.attributes.uuid,staticClass:"media-library-item media-library-item-row",attrs:{"data-media-library-uuid":object.attributes.uuid}},[(_vm.sortable)?_c('div',{staticClass:"dragula-handle media-library-row-drag"},[(state.media.length)?_c('icon',{attrs:{"icon":"drag"}}):_vm._e()],1):_vm._e(),_c('MediaLibraryThumb',{attrs:{"upload-info":object.upload,"validation-rules":_vm.validationRules,"img-props":{
                                src: object.attributes.medium || object.attributes.preview_url || object.client_preview,
                                alt: object.attributes.name,
                                extension: object.attributes.name ? object.attributes.name.split('.').pop() : '',
                                size: object.attributes.size,
                            }},on:{"open":function($event){return _vm.openPopup(object)}}}),(getErrors(object).length)?_c('item-errors',{attrs:{"object-errors":getErrors(object)},on:{"back":function($event){return clearObjectErrors(object)}}}):[_vm._t("properties",function(){return [_c('div',{staticClass:"media-library-properties media-library-properties-fixed"},[(object.attributes.extension)?_c('div',{staticClass:"media-library-property"},[_vm._v(" "+_vm._s(object.attributes.extension.toUpperCase())+" ")]):_vm._e(),(object.attributes.size)?_c('div',{staticClass:"media-library-property"},[_vm._v(" "+_vm._s((object.attributes.size / 1024).toFixed(2))+" ")]):_vm._e()])]},{"object":object}),_vm._t("fields",function(){return [_c('div',{staticClass:"media-library-properties"},[_c('div',{staticClass:"media-library-field"},[_c('label',{staticClass:"media-library-label"},[_vm._v(" "+_vm._s(_vm.window.mediaLibraryTranslations.name)+" ")]),_c('input',_vm._g({staticClass:"media-library-input",attrs:{"disabled":!_vm.allowImageUploading,"dusk":"media-library-field-name"},domProps:{"value":object.attributes.name}},getNameInputListeners(object))),(object.upload)?_c('div',{class:`media-library-progress-wrap mt-[5px] ${
                                                object.upload.hasFinishedUploading ? '' : 'media-library-progress-wrap-loading'
                                            }`},[_c('progress',{staticClass:"media-library-progress",attrs:{"max":"100"},domProps:{"value":object.upload.uploadProgress}})]):_vm._e(),_vm._l((getNameInputErrors(object)),function(error){return _c('p',{key:error,staticClass:"media-library-field-error"},[_vm._v(" "+_vm._s(error)+" ")])})],2)])]},{"object":object,"getCustomPropertyInputProps":(propertyName) => getCustomPropertyInputProps(object, propertyName),"getCustomPropertyInputListeners":(propertyName) => getCustomPropertyInputListeners(object, propertyName),"getCustomPropertyInputErrors":(propertyName) => getCustomPropertyInputErrors(object, propertyName),"getNameInputProps":() => getNameInputProps(object),"getNameInputListeners":() => getNameInputListeners(object),"getNameInputErrors":() => getNameInputErrors(object)})],(_vm.allowImageUploading)?_c('div',{staticClass:"media-library-row-remove",attrs:{"dusk":"remove"},on:{"click":function($event){$event.stopPropagation();return _vm.handleMediaRemoval(removeMedia, object)}}},[_c('icon',{attrs:{"icon":"remove"}})],1):_vm._e()],2)}),0),_c('hidden-fields',{attrs:{"name":_vm.name,"media-state":state.media}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.maxItems || state.media.length < _vm.maxItems),expression:"!maxItems || state.media.length < maxItems"}],staticClass:"media-library-uploader"},[_c('uploader',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.allowImageUploading),expression:"allowImageUploading"}],attrs:{"add":"","multiple":"","file-type-help-text":_vm.fileTypeHelpText}},'uploader',{ ...getDropZoneProps(), ...getFileInputProps() },false),{ ...getDropZoneListeners(), ...getFileInputListeners() }))],1)],1)],1)}}])}),(_vm.showMediaPopup)?_c('vue-easy-lightbox',{attrs:{"esc-disabled":"","visible":_vm.showMediaPopup,"imgs":[
            {
                src: _vm.getMediaURL,
                alt: _vm.selectedMedia.name,
                caption: _vm.selectedMedia.name
            }
        ]},on:{"hide":function($event){_vm.showMediaPopup = false, _vm.selectedMedia = {}}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
// Job Allocation Routes
import JobAllocationDetailsView from '@/views/admin/job-allocation/JobAllocationDetailsView'

export default [
    {
        path: 'job-allocation',
        name: 'JobAllocationView',
        component: () =>
            import('@/views/admin/job-allocation/JobAllocationView'),
        meta: {
            title: 'My Abseil - Job Allocation',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'job-allocation/:id/:date?',
        name: 'JobAllocationDetailsView',
        component: JobAllocationDetailsView,
        meta: {
            title: 'My Abseil - Job Allocation - Client',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
